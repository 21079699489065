import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import React from 'react';
import { AnimatedDualMedia } from 'src/components/AnimatedDualMedia';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { CheckListItem } from 'src/components/CheckListItem';
import { LandingFeatureGrid } from 'src/components/Landing/LandingFeatureGrid';
import { MediaSection } from 'src/components/MediaSection';
import { MethodsLandingHero } from 'src/components/MethodsLandingHero';
import { ClientList } from 'src/components/OurClientsAlt/OurClientsAlt';
import { Paragraph } from 'src/components/Paragraph';
import { ScrollingList } from 'src/components/ScrollingList';
import { scrollListItems } from 'src/components/ScrollingList/scrollListItems';
import { Section } from 'src/components/Section';
import { ThemeProvider } from 'src/components/ThemeProvider';

export default function Publisher({ data }) {
    return (
        <Layout
            pageTitleFull="Online community platform for publishers &amp; content creators"
            siteDescription="Increase audience engagement, expand revenue streams, and enhance memberships with a community supported by Audentio!"
            contactForm
            // useLandingNav
        >
            <MethodsLandingHero
                title="Build a loyal community of readers around your content"
                video="/videos/targeted/publisher/publisher-hero.mp4"
                videoPoster="/videos/targeted/publisher/publisher-hero-poster.jpg"
                videoId="streaming-person-talking"
                subtitle="You’ve already begun sharing your story with the world but your articles or blog aren’t just a monologue. Increase audience engagement and revenue by fostering conversations in a community."
            />

            <LandingFeatureGrid
                title="A community platform to drive engagement to your articles and build a membership."
                items={[
                    {
                        heading: 'WordPress integration and development',
                        content:
                            'Connect your website or blog with a forum to create a seamless experience between your WordPress theme and your community. ',
                    },
                    {
                        heading: 'subscriptions and donations',
                        content:
                            'Monetize your way by having subscription tiers, donations, and advertisements.',
                    },
                    {
                        heading: 'paywall premium content',
                        content:
                            'Make content only accessible to subscribers with exclusive events and premium-only discussion.',
                    },
                    {
                        heading: 'chat and discussion',
                        content:
                            'Have real-time discussion with members and also member-to-member engagement.',
                    },
                    {
                        heading: 'question and answer',
                        content:
                            'Make it simple for members to answer each other’s questions and helpful for the future by indicating the best answer.',
                    },
                    {
                        heading: 'leaderboard and awards',
                        content:
                            'Highlight members for their high-level of activity with leaderboard and achievements.',
                    },
                ]}
                mb="0"
            />

            <MediaSection
                title="Why do I need a community platform?"
                pb={[0, 0, 0]}
                flipped
                middleAlign
                customMedia={<ScrollingList items={scrollListItems} />}
            />

            <MediaSection
                id="technology"
                bg="primaryBackground"
                video="/videos/targeted/publisher/migration.mp4"
                videoId="landing-publisher-migration"
                title="Communities we’ve helped build for publishers"
            >
                <Box as="p" lineHeight="1.5">
                    We're not your average WordPress development or design
                    agency. Our speciality is building what brands need to more
                    effectively engage with their audience with real-time
                    discussion, gamification, and social events.
                </Box>
                <Button
                    mt="12"
                    maxWidth="140px"
                    href="https://www.audent.io/case-studies"
                >
                    learn more
                </Button>
            </MediaSection>

            <Section vertical="md" pt={[0, 0, 0]} wrap>
                <ClientList
                    title="Communities that trust us"
                    clients={[
                        { name: 'IGN', image: data.ign },
                        { name: 'cPanel', image: data.cpanel },
                        { name: 'Yahoo!', image: data.yahoo },
                        { name: 'MacRumors', image: data.macrumors },
                        { name: "Tom's hardware", image: data.tomshardware },
                    ]}
                    borderTop="1px"
                    borderColor="border"
                    pt={['spacing-lg', '40px', 'spacing-xl']}
                />
            </Section>

            <ThemeProvider defaultMode="dark">
                <MediaSection
                    id="nativeapp"
                    //image={data.appsub.childImageSharp.fluid}
                    title="A dedicated native app for WordPress and your community"
                    //flipped
                    bg="primaryBackground"
                    customMedia={
                        <AnimatedDualMedia
                            image={data.articlelisting.childImageSharp.fluid}
                            alt1="Community platform for WordPress publishers to host their articles and readers in one place for engagement."
                            image2={data.articleview.childImageSharp.fluid}
                        />
                    }
                >
                    <Box pt="spacing" as="ul">
                        {[
                            {
                                title: 'Reach your audience where they are',
                                content:
                                    'Stay in touch with native push notifications on iOS and Android.',
                            },
                            {
                                title: 'Get listed in app stores',
                                content:
                                    'Gain exposure to a wider audience and a younger demographic.',
                            },
                            {
                                title: 'Expand your revenue opportunities',
                                content:
                                    'An avenue for placing mobile ads, app sales, and subscriptions.',
                            },
                        ].map(highlight => (
                            <CheckListItem
                                key={highlight.title}
                                title={highlight.title}
                                as="li"
                            >
                                {highlight.content}
                            </CheckListItem>
                        ))}
                    </Box>
                </MediaSection>
            </ThemeProvider>

            <MediaSection
                id="stream"
                flipped
                image={data.streamingphone.childImageSharp.fluid}
                title="Interact with your audience through live streaming"
                backgroundColor="secondaryBackground"
                alt="Streaming platform free of third-party branding that supports chat and moderation."
            >
                <Paragraph>
                    Streaming from your own platform gives you more control and
                    the ability to keep engagement on your platform.
                </Paragraph>
                <Box pt="spacing">
                    {[
                        {
                            title: 'Decide your percentage cut',
                        },
                        {
                            title: 'Optional ad-free experience',
                        },
                        {
                            title: 'Chat and moderation',
                        },
                        {
                            title:
                                'Integrate live streaming into your website and community',
                        },
                    ].map(highlight => (
                        <CheckListItem
                            key={highlight.title}
                            title={highlight.title}
                        />
                    ))}
                </Box>
            </MediaSection>
        </Layout>
    );
}

export const query = graphql`
    query PublisherQuery {
        vbhero: file(
            relativePath: { eq: "images/vbsoftware/talking-point.jpg" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        revenuestreams: file(
            relativePath: { eq: "images/xfsoftware/revenue-streams.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streaminterface: file(
            relativePath: { eq: "images/xfsoftware/stream-interface.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        appsub: file(
            relativePath: { eq: "images/membership/app-subscription.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        streamingphone: file(
            relativePath: { eq: "images/membership/streaming.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        articlelisting: file(
            relativePath: { eq: "images/app-method/article-listing.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        articleview: file(
            relativePath: { eq: "images/app-method/article-view.png" }
        ) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ign: file(relativePath: { eq: "images/clients/ign.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        tomshardware: file(
            relativePath: { eq: "images/clients/toms-hardware.png" }
        ) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        macrumors: file(relativePath: { eq: "images/clients/macrumors.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        yahoo: file(relativePath: { eq: "images/clients/yahoo-sports.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }

        cpanel: file(relativePath: { eq: "images/clients/cpanel.png" }) {
            publicURL
            childImageSharp {
                fluid(maxWidth: 200) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`;
